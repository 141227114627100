import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as Constants from "./config/config.js";
import i18nBackend from "i18next-http-backend";
import * as Globals from "./config/GLOBALS.js";

i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    lng: Constants.LANG,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
            close:"Close",
          a_link_to:"A link to continue will be sent to your email address.",
          submit:"Submit",
          us_guide:"Online Expedited Form",
          cannot_send_message:"Cannot Send Message",
          online_esta:"Online Expedited Form",
          online_eta_il:"Online Expedited ETA-IL Form",
          online_eta:"Online Expedited Form",
          canada_guide:"Online Expedited Form",
          finish_later:"Finish Later",
          finish_later_title:"Want to finish filling form later?",
          faq: "FAQ",
          card_number_not_valid: "Card number is not valid",
          faq1:"What is the processing time for ",
          faq2:" Visa Online?",
          faq3:"A visa application for ",
          faq4:" takes about two days to process, depending on the kind of visa. In general, all types of applications require only 48 to 72 working hours to process. You may also obtain a visa in less than 24 hours with express service.",
          faq5: "How to track the status of the ",
          faq6: " Travel Visa?",
          faq7: "When completing the application form, we take the email address and Whatsapp number of the guest. All updates, acknowledgments, and notifications will be sent to this registered email address. Additionally, you may receive updates via WhatsApp messages. Furthermore, the real-time status of the application can be tracked on our website. You are required to use the reference number provided by us for tracking purposes.",
          faq8:"What are the documents necessary for a ",
          faq9:" Visa for Tourists?",
          faq10:"To obtain a tourist visa for  ",
          faq11: ", you will need the following documents:<br/> Passport (which should be valid for 6 Months from the date of arrival)<br/> Passport size Photograph",
          faq12 :"What is the duration of the ",
          faq13: " Visa for Tourists?",
          delivery_issue: "Delivery Help",
          delivery_issue_title: "Report Delivery Issue",
          delivery_issue_context: "If you experience any delay or issue please leave a message and our team will get back to you as soon possible",
          faq14 :"When it comes to ",
          faq141: " traveler visas, there are certain limits on how long you may stay. The duration of a ",
          faq15:" travel visa is between 30 and 90 days. This visa is valid for both single and repeated trips. The duration of a multiple visit visa is six months, with a 30-day validity period for each trip.",
          faq16 :"Can a group or a family apply for an online ",
          faq17 :" Visa?",
          faq18: "Yes. You can simply create multiple forms for each member of the family.",
          faq19 :`Why should I choose ${Globals.SERVER} Visa?`,
          faq20: `${Globals.SERVER} provides exceptional services and value for money. No one can beat our prices. We are also AI experts that created all the Visa process smoothly and effectively.`,
          faq21: `Why is with ${Globals.SERVER} the visa received the fastest possible?`,
          faq22: `${Globals.SERVER} holds innovative technology that does everything 24/7 without human touch. The submission is faster than anyone else in the market. In normal companies, there are agents who add to submit , they may make mistakes and create delays.`,
          faq23: "What is an emergency letter?",
          faq24: "Our AI technology generates an emergency letter for the targeted language of the destination, this is being sent to the authorities together with the visa application. We raise personal urgent reasons that in most cases release the visa earlier than anybody else.",
          faq25: "Is Online Payment Safe?",
          faq26:`Yes. While using the ${Globals.SERVER} website, you are in the safest and secure environment. All transactions are encrypted. The website exercises strict security protocols.`,
          faq27:"What if I have entered wrong/incorrect information in the online form by mistake?",
          faq28: `At ${Globals.SERVER}, visa specialists are experienced in handling these types of shortcomings. The application form provides an option for editing the application form. For further assistance, you can drop a message with the chat support team.`,
          faq29: "How Do I apply for an online ",
          faq30:" visa?",
          faq31: `Log on to <a class="orange floatNone" href="https://${Globals.SERVER_URL}" target="_blank" rel="noopener">${Globals.SERVER_URL}</a>. Choose your destination, check your nationality on the home page and proceed. You will be required to fill in the online application form and upload documents. After making the necessary payments, you will receive notifications and the visa in your inbox of the registered email address. You are required to carry a copy of the email while traveling to  `,
          faq32: `. At ${Globals.SERVER} Travel, visa specialists are experienced in handling these types of shortcomings. The application form provides an option for editing the application form. For further assistance, you can drop a message with the chat support team.`,
          faq33:"What if I have entered wrong/incorrect information in the online form by mistake?",
          faq34: `At ${Globals.SERVER}, visa specialists are experienced in handling these types of shortcomings. The application form provides an option for editing the application form. For further assistance, you can drop a message with the chat support team.`,
          order_num: "Order/Form Number:",
          cvc_is_invalid:"CVC is invalid",
          expiration_is_invalid:"Expiration is invalid",
          card_owner_issue:"Card owner name is invalid",
          payment_failed:"Payment failed, please follow the email instructions you will receive in few minutes",
          review1: "Bill Anderson",
          maximize_your_time_1: "Maximize Your Time:",
          maximize_your_time_2: "Expert Application Processing ",
          cryptopay: "Cryptocurrency",
          review2: "I am completly content with the result of the work on my visa! No registration and additional password needed. Your first request is practically the registration. I\'ve submitted my request in advance so they gave me an exact date when my request will be worked on. The given date was the day when I received it. Thank you!!!",

          not_included: "Not Included",
          included: "Included",
          nationality: "Nationality",
          entry_online_expedited: "Online Expedited Entry Form",
          form_online_expedited: "Online Expedited Form",
          important_note: "Transparency Note: No Hidden Costs",
          very_important_note:"Very Important Note!",
          //important_note: "Important Note: Valid Across All Airports",
          //important_note: "Industry-Leading AI-Powered eVisa RT Technology",
          //important_note:"Swift, Precise AI-Driven Application Process",
          important_note:"AI-Driven, Immediate Submission Approval",
          apply_now_get_expert:"Apply Now - Get Expert Help!",
          disclaimer_top:"Disclaimer: GOVIS is independent and not affiliated or endorsed by the U.S. Government. Services are available directly from the ESTA website at no additional cost. GOvis offers extra guidance and live email support for a fee.",
          disclaimer_top_canada:"Disclaimer: GOvis is independent and not affiliated or endorsed by the Canada Government. Services are available directly from the ETA website at no additional cost. GOvis offers extra guidance and live email support for a fee.",
          disclaimer_top_payfine:"Disclaimer: GOvis operates as an independent, full-service ticket payment provider and is not affiliated with any official government websites. We uniquely offer the convenience of securely storing your payment reference in your account vault, ensuring you can easily access and prove payment whenever necessary.AI-driven system that verifies and confirms your ticket payments.",
          dialog_guided_us:"Get ESTA by yourself at esta.cbp.dhs.gov. No live support. Small mistakes can mean no approval! Our service helps avoid mistakes and supports you all the way.",
          disclaimer_add_us: "GOVIS is not affiliated with any US government agency or department. You can apply yourself directly at esta.cbp.dhs.gov.",
          disclaimer_add_canada: "GOVIS is not affiliated with any Canadian government agency or department. You can apply yourself directly at onlineservices-servicesenligne.cic.gc.ca/eta.",
          dialog_guided_canada: "Get ETA by yourself at onlineservices-servicesenligne.cic.gc.ca. No live support. Small mistakes can mean no approval! Our service helps avoid mistakes and supports you all the way.",
          service_type: "Service Type",
          extra_services: "Emergency Services",
          extra_services_payfine: "Subscription Services",
          first_name_middle: "First Name & Middle Name",
          last_name: "Last Name",
          current_address: "Current Address",
          mobile: "Mobile",
          city: "City",
          state: "State",
          zip: "Zip Code",
          email: "Email Address",
          email_repeat: "Repeat Email Address",
          email_repeat_exp: "Email Address has to be Identical",
          dob: "Date of Birth",
          passport_number: "Passport Number",
          passport_expiry: "Passport Expiry",
          eta: "Estimated Arrival Date",
          personal_information: "Personal Information",
          passport_details: "Passport Details",
          arrival_date: "Arrival Date",
          next: "Next",
          home: "Home",
          quick_facts: "Quick Facts",
          things_to_be_done: "Things to be Done",
          travel_guide: "Travel Guide",
          facts_and_figures: "Facts and Figures",
          contact_us: "Contact us",
          live_chat: "Live Chat",
          //total_fee: "*Maximum Fees",
          total_fee: "Total Fees",
          total_fee_cost: "Fee",
          processing_time: "Processing Time",
          processing_time_payfine: "Processing Time",
          visa_validity: "Visa Validity",
          stay_validity: "Stay Validity",
          visa_validity_payfine: "Ticket Payment",
          stay_validity_payfine: "Stay Validity",
          refund: "100% refund guarantee*, if not approved by the immigration department",
          final_fee: "*Your final fee will be calculated based on the information provided. Upon completing the form, the system will automatically calculate and present the fee.",
          //final_fee: "",
          twelve_working_hours: "12 Working Hours",
          seven_two_working_hours: "72 Working Hours",
          six_working_hours: "6 Working Hours",
          twenty_four_working_hours: "24 Working Hours",
          processing_time_emergency: "Processing Time (Emergency)",
          millions_customers: "Only Happy Customers",
          experience: "Experienced Team",
          delivery: "99% On Time Delivery",
          top_rated: "Highly Rated",
          tips_to_stay_in: "Tips for Your Stay in ",
          things_to_be_done_once: "Things to be done once you reach destination",
          mind_customs: " Mind Customs Regulations: Make sure you're not carrying any items prohibited by customs. Familiarize yourself with the list of restricted items to smoothly pass through customs.",
          keep_passport_1: "Keep Passport Copies: While exploring ",
          keep_passport_2: ", always carry a copy of your passport or the original document. This will help in case you need to verify your identity.",
          know_emergency: "Know Emergency Numbers: Memorize and jot down important emergency contact numbers. Having them at hand ensures swift assistance in case of any unforeseen situation.",
          adhere_rules_1: "Adhere to Rules: Familiarize yourself with the rules and regulations of ",
          adhere_rules_2: "Abide by local customs, laws, and etiquette to have a respectful and enjoyable experience.",
          about_us: "ABOUT US",
          about_us_1:'We are on a quest to redefine travel. Come! And experience travel like none.',
          about_us_2:'offers a 360-degree travel solution. AI Tourism is a long-serving entity in international travel. We understand and are all ears to the travellers approaching us for their queries and travel-related Q & A.',
          about_us_3:'We are an agency. We also help applicants with difficult cases or in an emergency. Our goal is to create the most favorable conditions for applying online',
          payment_methods:'Payment Methods',
          head_office:"Head Office located at 2875 NE, 191 st, Suite 601, 33180 Aventura, United States of America.",
          disclaimer:"This website is not affiliated with the government of {GOV}. In order to provide you with the best online experience this website uses cookies. By using our website, you agree to our use of cookies. We are an independent agency which is not related to the government of {GOV}. Our agency offers fast and professional assistance in obtaining the required approval to travel to {GOV}.",
          disclaimer_main:"This website is not affiliated with any government. In order to provide you with the best online experience this website uses cookies. By using our website, you agree to our use of cookies. We are an independent agency which is not related to any government. Our agency offers fast and professional assistance in obtaining the required approval to travel.",
          cookie_consent:"This website uses cookies to enhance the user experience.",
          cookie_accept:"Accept",
          all_rights_reserved:"All rights Reserved." ,
          ways_to_connect_with_us: "Contact Us",
          online_chat_assist: "Online Chat Assist",
          address: "Address",
          card_number: "Card Number",
          card_owner: "Card Owner",
          card_expiration: "Expiration MM/YY",
          card_cvc: "CVC",
          our_team: "Our team is available 24x7 to assist you",
          once_your_order: "Once you have placed your order, you\'ll receive an email to confirm your personal information",
        //  once_your_order: "Upon placing your order, immediate preprocessing begins; you'll receive an email confirmation, and our team may request follow-ups based on your application.",
          street: "2875 NE, 191st, Suite 601",
          city_zip: "33180, Aventura",
          usa: "USA",
          expedited_fee: "Total Fee: ",
          //terms: "I hereby declare that the information provided is true and correct",
          //terms: "I have read and agree with the terms and conditions and acknowledge no-refund policy after the service is processed and delivered.",
          terms: "I have read and agree with the terms and conditions",
          terms_square: "By pressing on payment you accept the terms and conditions",

          country: "Country",
          product: "Product",
          total: "Total",
          emergency: "Emergency",
          your_ref: "Your reference is:",
          paymnet_error: "Payment Error",
          thank_you: "Thank you for the payment!",
          follow_up: "Please follow further instructions in email",
          //payment_appear: "*Payment will be shown on credit card statement as kosherwhere llc",
          payment_appear: "",
          back_home: "Back to Home",
          place_order: "Place Order",
          reveal_pricing1: "Select Nationality & Service Type to View",
          reveal_pricing2: "Fees & Terms",
          reveal_pricing1_payfine: "Select Country & Service Type to Pay Your Ticket Now",
          reveal_pricing2_payfine: "",
          privacy_matter:"Your privacy matters. We use your info only for this application, sharing only with visa authorities. No unwanted emails, just updates and support responses.",
          //reveal_pricing2: "More Details",
          //reveal_pricing2: "Details & Terms",
          one_minute: "1-2 MIN PROCESS",
          details_fees: "DETAILS & FEES",
          pay_traffic_fines: "PAY YOUR TRAFFIC FINES IN ANY CURRENCY",
          email: "Email ",
          welcome:` Welcome to ${Globals.SERVER} `,
          online_form:" Online Form ",
          welcome_form:`Welcome to ${Globals.SERVER} Online Form `,
          order_summary:"Order Summary",
          best_price:"Gov Signed",
        //  best_price:"Official Document",
          thank_you: 'Thank you!',
          twenty_four_seven:"24/7 Service",
          trusted_by: "Trusted by customers globally",
          payment_details: "Payment Details",
          gov_inc: "*Government Fee",
          gov_inc_flat: "*Including Government & Express Processing Fees",
          tbd_text: "*Fees are calculated from applied information",
          //tbd_text: "",
          service_fees: "Expedited Service Fee",
          government_fees: "Government Fee",
          emergency_fees: "Emergency Letter",
          //gov_inc: "*Including government & express processing",
          //emergency_letter_warning: "*An emergency letter or expedited service doesn't guarantee that your eVisa will arrive earlier in case immigration having a delay or requests more information. However, it is increasing the chances of getting it faster than the normal submitted application as it puts it on top of the list.",
          emergency_letter_warning: "*Expedited eVisa applications (incl. emergency letter) are processed faster but unforeseen immigration delays or additional requests can affect the timeline.",
          sent_by_email: "PDF sent to your email",
          payment_method: "Payment Method",
          digital_delivery: "Digital Delivery",
          Emma: "Emma",
          loading: "Loading...",
          visa_processing_steps: "Visa Processing Steps",
          step1: "Step 1",
          step2: "Step 2",
          step3: "Step 3",
          step4: "Step 4",
          apply_visa_online: "Apply Visa Online",
          pay_visa_fees: "Pay Visa Fees Online",
          visa_in_process: "Visa in Process",
          print_visa: "Print Visa & Fly",
          click_here_visa:" Click Here to View Sample Visa Copy",
          example_visa: "Example Visa",
          faq: "FAQ",
          successfully_process:"We've successfully processed hundreds of thousands of transactions in recent years, with countless happy and returning customers. Our human support consists of first-class, experienced agents.",
          form_authority:"Form Authority Fee",
          error_free:"Error-Free Guarantee Pre Processing Form Check",
          stay_informed:"Stay Informed - Proactive Email Explained Status Alerts",
          personalized_assitance:"24/7 Personalized Assistance - Unlimited Guided Support",
          seamless_service:"Seamless Continuation Service",

          what_is_the_processing_time:"What is the processing time for ",
          visa_online: " Visa Online?",
          a_visa_application_for:"A visa application for ",
          takes_about:" takes about two days to process, depending on the kind of visa. In general, all types of applications require only 48 to 72 working hours to process, You may also obtain the visa with in less than 24 hours with express service",
          how_to_track_the_status:"How to track the status of the " ,
          travel_vist:" Travel Visa?",
          when_completing:"When completing the application form, we take the email address and whatsapp number of the guest. All updates, acknowledgments, and notifications will be sent to this registered email. Additionally, you may receive updates via WhatsApp messages. Furthermore, the real-time status of the application can be tracked on our website. You are required to use the reference number provided by us for tracking purposes.",
          error_phone_number:"Enter Phone Number",
          error_select_nationality:"Select Nationality",
          error_select_service:"Select Service Type",
          error_select_first:"Enter First Name",
          error_select_last:"Enter Last Name",
          error_select_city:"Enter City",
          error_select_zip:"Enter Zip Code",
          error_select_email:"Enter Email Address",
          error_select_birth:"Enter Date of Birth",
          error_select_valid_email:"Enter Valid Repeat Email Address",
          error_select_passport_number:"Enter Passport Number",
          error_select_passport_expiry:"Enter Passport Expiry",
          error_select_passport_arrival_date:"Enter Estimated Arrival Date",
          error_select_enter_valid_email:"Enter Valid Email",
          error_select_enter_valid_repeat_email:"Enter Valid Repeat Email",
          and:" And ",
          payment_button:'Pay',
          describe_issue:"Describe the issue",
          selected_service:"Visa Type",
          full_name:"Full Name:",
          credit_debit_stripe: "Stripe",
          credit_debit_square: "Credit/Debit Card",
          credit_debit_zen: "Credit-Debit Card",
          paypal:"Paypal",
          no: "No",
          yes: "Yes",
          authority_process: "Authority Process Fee",
          effortless_ai_experience: 'Effortless AI Experience',
          real_time_process: ' Real Time Processing Experience',
          in_case_you_did_not:"In case you did not receive the final delivery email or received it after the expected delivery time:",
          dear_customer_we_thank:"Dear customer, we thank you and appreciate your order. Please note this very important process to help you in case of delivery issues.",
          it_is_important_that:"It is important that you contact our VIP support with the form above to report any issues with the delivery as soon as possible.",
          please_do_not_dispute:"Please DO NOT dispute the charge with your bank as this may result in automated AI procedures beyond our control. In some cases, disputing a legitimate charge is a legal offense and may lead to the following consequences:",
          immediate_cancellation:"Immediate cancellation of the submitted form.",
          notification_of_the_authority:"Notification of the authorities, which may result in criminal actions in your destination.",
          notification_of_your_own:"Notification of your own country authorities of a possible criminal offense.",
          other_legal_procedural:"Other legal and procedural actions.",
          to_avoid_these_possible:"To avoid these possible unpleasant actions, please contact us as soon as possible so we can assist you through our advanced system and consultants team.",
          you_can_reply_to:"You can reply to this email.",
          thank_you_for_your:"Thank you for your understanding and cooperation.",
          enter_full_name:"Enter Full Name",
          enter_description : "Enter Issue Description",
          cancel : "Cancel",
            your_msg_sent_suc:"Your message was sent successfully.",
              enter_order_num: "Please Enter Order/Form Number"













        },
      },
      he: {
        translation: {
            close:"סגור",
          submit:"שלח",
            describe_issue:"נא לתאר מה הבעיה באנגלית",
          finish_later:"לסיים מאוחר יותר",
          finish_later_title:"מעוניין/ת לסיים את הטופס מאוחר יותר?",
          faq: "שאלות ותשובות",
          faq1:"כמה לוקח ל",
          faq2:" אונליין ויזה?",
          faq3:"טופס ויזה בשביל ",
          faq4:" לוקח בערך יומיים לעיבוד, תלוי בסוג הויזה. באופן כללי, כל סוגי הבקשות דורשות רק 48 עד 72 שעות עבודה לעיבוד, ניתן גם לקבל את הויזה תוך פחות מ-24 שעות עם שירות אקספרס",
          faq5: "כיצד לעקוב אחר הסטטוס של ",
          faq6: " ויזת נסיעות?",
          faq7: "בעת מילוי טופס הבקשה, אנו לוקחים את כתובת המייל ומספר הווטסאפ של האורח. כל העדכונים, האישורים וההתראות יישלחו לאימייל הרשום הזה. בנוסף, ייתכן שתקבלו עדכונים באמצעות הודעות WhatsApp. יתר על כן, ניתן לעקוב אחר מצב האפליקציה בזמן אמת באתר האינטרנט שלנו. אתה נדרש להשתמש במספר האסמכתה שסופק על ידינו למטרות מעקב.",
          faq8:"לשם מה המסמכים הדרושים ",
          faq9:" ויזה לתיירים?",
          faq10:"לקבלת אשרת תייר עבור  ",
          faq11: ", תזדקק למסמכים הבאים:<br/>דרכון (שאמור להיות תקף ל-6 חודשים מתאריך ההגעה)<br/>תמונה בגודל דרכון",
          faq12 :"מהו משך הזמן של ",
          faq13: " ויזה לתיירים?",
          cvc_is_invalid:"ספרות הביקורת לא תקינות",
          expiration_is_invalid:"תוקף לא תקין",
          card_owner_issue :"שם בעל כרטיס לא תקין",
          payment_failed:"תשלום נכשל, אנא המשך מהאימייל שישלח אליך בדקות הקרובות",
          faq14 :"כשזה מגיע ל ",
          faq141: "ויזות מטיילים, יש מגבלות מסוימות על כמה זמן אתה יכול להישאר. משך הזמן של א ",
          faq15:" אשרת נסיעה היא בין 30 ל-90 יום. ויזה זו תקפה הן לטיולים בודדים והן לטיולים חוזרים. משך אשרת ביקור מרובה הוא שישה חודשים, עם תקופת תוקף של 30 יום לכל טיול.",
          faq16 :"האם קבוצה או משפחה יכולה להגיש בקשה מקוונת ",
          faq17 :" וִיזָה?",
          faq18: "כן. אתה יכול פשוט ליצור טופס מרובים עבור כל חבר במשפחה.",
          faq19 :`מדוע עלי לבחור בוויזת ${Globals.SERVER}?`,
          faq20: `${Globals.SERVER} מספקת שירותים יוצאי דופן ותמורה לכסף, אף אחד לא יכול לנצח את המחירים שלנו, גם אנחנו מומחי AI שיצרו את כל תהליך הוויזה בצורה חלקה ויעילה`,
          cryptopay: "מטבע דיגיטלי",
          faq21: `למה עם ${Globals.SERVER} הוויזה קיבלה הכי מהר שאפשר?`,
          faq22: `${Globals.SERVER} מחזיקה בטכנולוגיה חדשנית שעושה הכל 24/7 ללא מגע אנושי, ההגשה היא המהירה ביותר מכל אחד אחר בשוק. בחברות רגילות יש סוכנים שמוסיפים להגשה, הם עלולים לעשות טעויות וליצור עיכובים.`,
          faq23: "מהו מכתב חירום?",
          faq24: "טכנולוגיית הבינה המלאכותית שלנו מייצרת מכתב חירום לשפת היעד הממוקדת, זה נשלח לרשויות יחד עם בקשת הויזה, אנו מעלים סיבות דחופות אישיות שברוב המקרים משחררות את הויזה מוקדם יותר מכל אחד אחר.",
          faq25: "האם תשלום מקוון בטוח?",
          faq26:`כן. בזמן השימוש באתר ${Globals.SERVER}, אתה נמצא בסביבה הבטוחה והמאובטחת ביותר. כל העסקאות מוצפנות. האתר מפעיל פרוטוקולי אבטחה קפדניים.`,
          faq27:"מה אם הזנתי בטעות מידע שגוי/שגוי בטופס המקוון?",
          faq28: `ב-${Globals.SERVER}, מומחי ויזה מנוסים בטיפול בחסרונות מסוג זה. טופס הבקשה מספק אפשרות לעריכת טופס הבקשה. לסיוע נוסף, תוכל לשלוח הודעה עם צוות התמיכה בצ'אט.`,
          faq29: "איך אני מגיש בקשה לקורס מקוון ",
          faq30:" ויזה?",
          faq31: `היכנס אל <a class="orange floatNone" href="https://${Globals.SERVER_URL}" target="_blank" rel="noopener">${Globals.SERVER_URL}</a>. בחר את היעד שלך, בדוק את הלאום שלך בדף הבית והמשיך. תידרש למלא את טופס הבקשה המקוון ולהעלות מסמכים. לאחר ביצוע התשלומים הדרושים, תקבלו הודעות ואת הויזה בתיבת הדואר הנכנס של כתובת המייל הרשומה. אתה נדרש לשאת עותק של המייל בזמן הנסיעה אל  `,
          faq32: `ב-${Globals.SERVER} Travel, מומחי ויזה מנוסים בטיפול בחסרונות מסוג זה. טופס הבקשה מספק אפשרות לעריכת טופס הבקשה. לסיוע נוסף, תוכל לשלוח הודעה עם צוות התמיכה בצ'אט.`,
          faq33:"מה אם הזנתי בטעות מידע שגוי/שגוי בטופס המקוון?",
          faq34: `ב-${Globals.SERVER}, מומחי ויזה מנוסים בטיפול בחסרונות מסוג זה. טופס הבקשה מספק אפשרות לעריכת טופס הבקשה. לסיוע נוסף, תוכל לשלוח הודעה עם צוות התמיכה בצ'אט.`,
          in_case_you_did_not:"במקרה שלא קיבלתם את האימייל הסופי עם הקובץ או שקיבלתם אותה לאחר זמן האספקה ​​הצפוי:",
          dear_customer_we_thank:"לקוח יקר, אנו מודים לך ומעריכים את הזמנתך. אנא שים לב לתהליך חשוב מאוד זה כדי לעזור לך במקרה של בעיות משלוח.",
          it_is_important_that:"חשוב שתיצור קשר עם תמיכת ה-VIP שלנו באמצעות הטופס למעלה כדי לדווח על כל בעיה עם קבלת הקובץ בהקדם האפשרי.",
          please_do_not_dispute:"נא לא לערער על החיוב עם הבנק שלך מכיוון שהדבר עלול לגרום להליכי AI אוטומטיים שאינם בשליטתנו. במקרים מסוימים, ערעור על אישום לגיטימי הוא עבירה משפטית ועלולה להוביל לתוצאות הבאות:",
          immediate_cancellation:"ביטול מיידי של המסמך.",
          card_number_not_valid: "מספר כרטיס אשראי לא תקין",
          notification_of_the_authority:"הודעה לרשות, שעלולה לגרום לפעולות פליליות ביעדך.",
          notification_of_your_own:"הודעה של רשויות המדינה שלך על עבירה פלילית אפשרית.",
          other_legal_procedural:"פעולות משפטיות ופרוצדורליות אחרות.",
          you_can_reply_to: "אתה יכול להשיב לאימייל זה.",
          thank_you_for_your:"תודה על ההבנה ושיתוף הפעולה",
          enter_full_name : "הכנס שם מלא באנגלית",
          enter_description : "הכנס את תיאור הבעיה",
          cancel : "ביטול",
          your_msg_sent_suc:"ההודעה נשלחה בהצלחה!",
          delivery_issue: "עזרה במשלוח",
          enter_order_num: "אנא הכנס מספר הזמנה/טופס",
          delivery_issue_title: "דווח על בעיית מסירה",
          delivery_issue_context: "אם את/ה חווה עיכוב או בעיה כלשהי, אנא השאר/י הודעה והצוות שלנו יחזור אליך בהקדם האפשרי",
          order_num: "מספר הזמנה/טופס:",
          not_included: "ללא",
          included: "כולל",
          nationality: "אזרחות",
          entry_online_expedited: "בקשה מקוונת מהירה",
          form_online_expedited: "בקשה מקוונת מהירה אונליין",
          //important_note: "הבקשה רלוונטית לגבי כניסה דרך האוויר והים",
        //  important_note: "הודעת שקיפות: ללא עלויות מוסתרות",
        //  important_note: "מובילי השוק בטכנולוגיית AI לויזות בזמן אמיתי",
          important_note: "הגשה מאושרת מיידית באמצעות בינה מלאכותית",
          service_type: "סוג שירות",
          extra_services: "שירותים נוספים",
          disclaimer_top:"Disclaimer: GOvis is independent and not affiliated or endorsed by the U.S. Government. Services are available directly from the ESTA website at no additional cost. GOvis offers extra guidance and live email support for a fee.",
          disclaimer_top_canada:"Disclaimer: GOvis is independent and not affiliated or endorsed by the Canada Government. Services are available directly from the ETA website at no additional cost. GOvis offers extra guidance and live email support for a fee.",
          disclaimer_top_payfine:"Disclaimer: GOvis operates as an independent, full-service ticket payment provider and is not affiliated with any official government websites. We uniquely offer the convenience of securely storing your payment reference in your account vault, ensuring you can easily access and prove payment whenever necessary.AI-driven system that verifies and confirms your ticket payments.",
            to_avoid_these_possible:"כדי להימנע מפעולות לא נעימות אפשריות אלו, אנא צור איתנו קשר בהקדם האפשרי כדי שנוכל לסייע לך באמצעות המערכת המתקדמת וצוות היועצים שלנו.",
          first_name_middle: "שם פרטי ואמצעי באנגלית",
          last_name: "שם משפחה באנגלית",
          current_address: "כתובת נוכחית באנגלית",
          mobile: "נייד",
          paymnet_error: "לא ניתן לאשר את התשלום",
          city: "עיר באנגלית",
          state: "מחוז",
          zip: "מיקוד",
          email: "כתובת אימייל",
          email_repeat: "חזרה כתובת אימייל",
          email_repeat_exp: "כתובת האימייל חייבת להיות זהה",
          dob: "תאריך לידה",
          passport_number: "מספר דרכון",
          passport_expiry: "תפוגת דרכון",
          effortless_ai_experience: 'חווית AI ללא מאמץ',
          eta: "תאריך הגעה משוער",
          personal_information: "פרטים אישיים",
          passport_details: "פרטי דרכון",
          tbd_text: "*העמלות הסופיות מחושבות על בסיס המידע שמוזן",
        //tbd_text: "",
         arrival_date: "תאריך הגעה",
         next: "הבא",
         home: "בית",
         quick_facts: "עובדות כלליות",
         things_to_be_done: "דברים לעשות",
         travel_guide: "מדריך למטייל",
         facts_and_figures: "עובדות ומספרים",
         contact_us: "צור קשר",
         live_chat: "צאט חי",
         total_fee: "סכום כולל",
         processing_time: "זמן טיפול",
         visa_validity: "תוקף הויזה",
         stay_validity: "זמן שהייה",
         refund: "100 אחוז החזר כספי במידה ולא מקבלים ויזה",
         //final_fee: "*העמלה הסופית שלך תחושב על סמך המידע שנמסר. לאחר מילוי הטופס, המערכת תחשב אוטומטית ותציג את העמלה.",
         final_fee: "",
         details_fees:"לפרטים ומחיר",
         maximize_your_time_1: "הזמן שלך חשוב:",
         maximize_your_time_2: "המומחים שלנו יעבדו את בקשתך",
         successfully_process:"ביצענו בהצלחה מאות אלפי עסקאות בשנים האחרונות, עם אינספור לקוחות מרוצים וחוזרים. התמיכה האנושית שלנו מורכבת מסוכנים מנוסים מהמעלה הראשונה.",
         twelve_working_hours: "12 שעות עבודה",
         twenty_four_working_hours: "24 שעות עבודה",
         processing_time_emergency: "זמן טיפול בהול",
         millions_customers: "עשרות אלפי לקוחות מרוצים",
         experience: "עשרות שנות נסיון",
         delivery: "99 אחוז מגיע בזמן",
         top_rated: "ביקורות טובות",
         tips_to_stay_in: "טיפים לשהייה ב-",
         things_to_be_done_once: "דברים לעשות ברגע הגעה ליעד",
         mind_customs: "שים לב לתקנות המכס: ודא שאינך נושא חפצים האסורים על ידי המכס. הכר את רשימת הפריטים המוגבלים כדי לעבור בצורה חלקה במכס.",
         keep_passport_1: "שמור עותקים של הדרכון ב- ",
         keep_passport_2: ", נשא תמיד עותק של הדרכון שלך או המסמך המקורי. זה יעזור למקרה שתצטרך לאמת את זהותך.",
         know_emergency: "הכר מספרי חירום: שנן ורשום מספרי קשר חירום חשובים. החזקתם בהישג יד מבטיחה סיוע מהיר בכל מצב בלתי צפוי.",
         adhere_rules_1: "הקפידו על הכללים: הכר את הכללים והתקנות של ",
         adhere_rules_2: "פעל לפי המנהגים, החוקים והנימוס המקומיים כדי להנות מחוויית טיול מכבדת ומהנה.",
         about_us: "עלינו",
         about_us_1:'פיתחנו מערכת חכמה של נסיעות באמצעות בינה מלאכותית, בואו לחוות את הטכנולוגיה שמציעה פתרון נסיעות מקיף עם וותק במתן שירותי נסיעות בינלאומיות. ב- ',
         about_us_2:'אנו קשובים היטב למטיילים הפונים אלינו לשאלותיהם ולשאלות ותשובות הקשורות לנסיעות.כסוכנות אנו מסייעים גם לפונים במקרים קשים או בשעת חירום לטיפול מהיר בבקשה. ',
         about_us_3:'המטרה שלנו היא ליצור את התנאים הנוחים ביותר להגשת הבקשה אונליין',
         payment_methods:'אמצעי תשלום',
         payment_button:'שלם',
         head_office:"המשרד הראשי נמצא בארצות הברית ברחוב 2875NE 191st אוונטרה פלורידה",
         all_rights_reserved:"כל הזכויות שמורות" ,
         ways_to_connect_with_us: "צור קשר",
         online_chat_assist: "צ'אט אונליין זמין 24/7",
         address: "כתובת",
         our_team: "הצוות שלנו זמין 24 שעות כדי לסייע לך",
         once_your_order: "ברגע ביצוע ההזמנה ישלח אימייל עם פרטים חשובים נוספים",
         street: "2875/191st סוויטה 601",
         city_zip: "33180, אוונטורה",
         six_working_hours: "6 שעות עבודה",
         usa: "ארצות הברית",
         expedited_fee: "עמלה כוללת: ",
         terms: "קראתי והסכמתי לכל התנאים",
         terms_square :"בלחיצה על תשלום אתה מקבל את התנאים וההגבלות",
         country: "מדינה",
         product: "מוצר",
         total: "סה\"כ",
         emergency: "מכתב חירום",
         your_ref: "מספר קבלה:",
         thank_you: "תודה רבה על התשלום!",
         follow_up: "נא לעקוב אחרי ההוראות שישלחו באימייל",
         real_time_process: 'טיפול בבקשות בזמן אמיתי',
         //payment_appear: "*התשלום בכרטיס אשראי יופיע כחברת kosherwhere llc",
         payment_appear: "",
         back_home: "חזרה לעמוד הבית",
         place_order: "בצע הזמנה",
         reveal_pricing1: "יש לבחור אזרחות וסוג שירות",
         reveal_pricing2: "לפרטים נוספים",
         //reveal_pricing2: "לצפות בתנאים",
         one_minute: "דקה למלא",
         email: "אימייל",
         welcome:`ברוכים הבאים ל ${Globals.SERVER} `,
         online_form:" טופס לרישום אונליין ",
         welcome_form:` ברוכים הבאים לטופס רישום אונליין של ${Globals.SERVER} `,
         order_summary:"סיכום הזמנה",
         best_price:"מסמך רשמי",
      //   best_price:"",
         twenty_four_seven:"שירות 24/7",
         trusted_by: "מיליוני אנשים ברחבי העולם בוטחים בצורת תשלום זו",
         payment_details: "פרטי תשלום",
         gov_inc: "*עמלת הגירה וממשלה",
         sent_by_email: "קובץ נשלח ישר באימייל",
         digital_delivery: "משלוח דיגיטלי",
         Emma: "אמה",
         loading: "טוען...",
         visa_processing_steps: "שלבים בהליך הויזה",
         step1: "שלב 1",
         step2: "שלב 2",
         step3: "שלב 3",
         step4: "שלב 4",
         apply_visa_online: "רישום הויזה אונליין",
         pay_visa_fees: "תשלום העמלות אונליין",
         visa_in_process: "הויזה בטיפול",
         print_visa: "הדפס את הויזה וטוס",
         click_here_visa:"לחץ כאן כדי לראות דוגמת ויזה",
         example_visa: "ויזה לדוגמא",
         error_phone_number:"מספר טלפון שגוי",
         error_select_nationality:"בחר אזרחות",
         error_select_service:"בחר סוג שירות",
         error_select_first:"הכנס שם פרטי",
         error_select_last:"הכנס שם משפחה",
         error_select_city:"הכנס עיר",
         error_select_zip:"הכנס מיקוד",
         error_select_email:"הכנס כתובת אימייל",
         error_select_birth:"הכנס תאריך לידה",
         error_select_valid_email:"הכנס כתובת אימייל זהה למקור",
         error_select_passport_number:"הכנס מספר דרכון",
         error_select_passport_expiry:"בחר תאריך תפוגה לדרכון",
         error_select_passport_arrival_date:"בחר תאריך הגעה משוער",
         error_select_enter_valid_email:"הכנס כתובת אימייל תקינה",
         error_select_enter_valid_repeat_email:"הכנס כתובת אימייל חזרה תקינה",
         and:" וגם ",
         selected_service:"סוג הויזה שנבחרה",
        //emergency_letter_warning: "*מכתב חירום אינו מבטיח ב 100 אחוז את ההגעה של הויזה מוקדם יותר אבל כן מגדיל את הסיכוי כי הרישום נכנס מיד לראש התור",
        emergency_letter_warning: "*בקשות מזורזות (כולל מכתב חירום) מטופלות מהר יותר, אך עיכובים בלתי צפויים בהגירה או בקשות נוספות לתיקונים עלולים להשפיע על ציר הזמן",
        disclaimer:"אתר זה אינו מזוהה עם ממשלת {GOV}. על מנת לספק לך את החוויה המקוונת הטובה ביותר אתר זה משתמש בעוגיות. על ידי שימוש באתר שלנו, אתה מסכים לשימוש שלנו בעוגיות. אנחנו סוכנות עצמאית שאינה קשורה לממשלת {GOV}. הסוכנות שלנו מציעה סיוע מהיר ומקצועי בקבלת האישור הנדרש לנסיעה אל {GOV}.",
        payment_method: "אמצעי תשלום",
        paypal: "פייפאל",
        credit_debit_square: "כרטיס אשראי",
        credit_debit_zen: "כרטיס חיוב",
        credit_debit: "כרטיס אשראי",
        service_fees: "עמלת שירות מהיר",
        government_fees: "עמלת ממשלה",
        emergency_fees: "עמלת מכתב חירום",
        apply_now_get_expert:"התחל עכשיו וקבל עזרה מהמומחים!",
        no: "לא",
        yes: "כן",
        cookie_consent:"אתר זה משתמש בקובצי Cookie כדי לשפר את חווית המשתמש.",
        cookie_accept:"אשר",
        authority_process: "עלות תהליך הרשות",
        total_fee_cost: "עמלה",
        gov_inc_flat: "*כולל עמלות ממשלה ודמי טיפול מהיר",
        a_link_to:"קישור להמשך יישלח לכתובת המייל שלך.",
        thank_you: 'תודה רבה!',
        card_number: "מספר כרטיס",
        card_owner: "שם בעל הכרטיס",
        card_expiration: "תוקף כרטיס",
        card_cvc: "3 ספרות ביקורת",
        biometric: "מספר דרכון ביומטרי",
        form_authority:"אגרת רשות טופס",
        error_free:"אחריות ללא שגיאות בדיקת טופס טרום עיבוד",
        stay_informed:"הישאר מעודכן - התראות סטטוס פרואקטיביות באימייל",
        personalized_assitance:"סיוע מותאם אישית 24/7 - תמיכה מודרכת ללא הגבלה",
        seamless_service:"מערכת טכנולוגית מתקדמת לטיפול מהיר ואיכותי",
        cannot_send_message:"לא ניתן לשלוח את ההודעה לשרת",
        full_name:"שם מלא באנגלית:",
        very_important_note:"הודעה חשובה מאוד!",





        },
      },
    },
  });

export default i18n;
