import React, { lazy, Suspense, useEffect } from "react";
import Box from '@mui/material/Box';
import * as Globals from "./config/GLOBALS.js";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MenuIcon from '@mui/icons-material/Menu';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ApprovalIcon from '@mui/icons-material/Approval';
import DialogContentText from '@mui/material/DialogContentText';
import Container from '@mui/material/Container';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import i18n from "./i18n.ts";
import { useTranslation } from "react-i18next";
import Avatar from '@mui/material/Avatar';
import BusinessIcon from '@mui/icons-material/Business';
import Button from '@mui/material/Button';
import * as Constants from "./config/config.js";
import TermsAndConditions from "./Terms.jsx"
import Privacy from "./Privacy.jsx"
import Tooltip from '@mui/material/Tooltip';
import ChatIcon from '@mui/icons-material/Chat';
import MenuItem from '@mui/material/MenuItem';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import SupportAgentTwoToneIcon from '@mui/icons-material/SupportAgentTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "./index.css";
import Review from "./Review.js";
import Divider from '@mui/material/Divider';

import { AppBar, Typography, Toolbar,Grid ,Card,CardMedia, CardContent, CardActions } from '@mui/material';

import { useDispatch, useSelector, useState } from "react-redux";

import "./css/header.css";


import DubaiJSON from "./mocks/Dubai/dubai.json";
import BrazilJSON from "./mocks/Brazil/brazil.json";
import PayfineJSON from "./mocks/Payfine/payfine.json";
import EthiopiaJSON from "./mocks/Ethiopia/ethiopia.json";
import VietnamJSON from "./mocks/Vietnam/vietnam.json";
import IndiaJSON from "./mocks/India/india.json";
import IsraelJSON from "./mocks/Israel/israel.json";
import MoroccoJSON from "./mocks/Morocco/morocco.json";
import MoroccoILJSON from "./mocks/Morocco-il/morocco-il.json";
import CanadaJSON from "./mocks/Canada/canada.json";
import IndiaILJSON from "./mocks/India-il/india-il.json";
import EthiopiaILJSON from "./mocks/Ethiopia-il/ethiopia-il.json";
import SrilankaILJSON from "./mocks/Srilanka-il/srilanka-il.json";
import SrilankaJSON from "./mocks/Srilanka/srilanka.json";
import UsILJSON from "./mocks/Us-il/us-il.json";
import UsJSON from "./mocks/Us/us.json";
import CanadaILJSON from "./mocks/Canada-il/canada-il.json";
import BahrainILJSON from "./mocks/Bahrain-il/bahrain-il.json";
import BahrainJSON from "./mocks/Bahrain/bahrain.json";
import NewzealandILJSON from "./mocks/Newzealand-il/newzealand-il.json";
import NewzealandJSON from "./mocks/Newzealand/newzealand.json";
import CambodiaILJSON from "./mocks/Cambodia-il/cambodia-il.json";
import CambodiaJSON from "./mocks/Cambodia/cambodia.json";



let contentjson="";
if (Constants.PLATFORM=="Ethiopia")
   contentjson=[...EthiopiaJSON];
else if (Constants.PLATFORM=="Dubai")
  contentjson=[...DubaiJSON];
else if (Constants.PLATFORM=="Vietnam")
  contentjson=[...VietnamJSON];
  else if (Constants.PLATFORM=="Brazil")
    contentjson=[...BrazilJSON];
  else if (Constants.PLATFORM=="Payfine")
    contentjson=[...PayfineJSON];
else if (Constants.PLATFORM=="India")
  contentjson=[...IndiaJSON];
else if (Constants.PLATFORM=="Morocco")
  contentjson=[...MoroccoJSON];
else if (Constants.PLATFORM=="Israel")
    contentjson=[...IsraelJSON];
else if (Constants.PLATFORM=="Canada")
contentjson=[...CanadaJSON];
else if (Constants.PLATFORM=="Morocco-il")
contentjson=[...MoroccoILJSON];
else if (Constants.PLATFORM=="India-il")
contentjson=[...IndiaILJSON];
else if (Constants.PLATFORM=="Ethiopia-il")
contentjson=[...EthiopiaILJSON];
else if (Constants.PLATFORM=="Srilanka-il")
contentjson=[...SrilankaILJSON];
else if (Constants.PLATFORM=="Srilanka")
contentjson=[...SrilankaJSON];
else if (Constants.PLATFORM=="Us-il")
contentjson=[...UsILJSON];
else if (Constants.PLATFORM=="Us")
contentjson=[...UsJSON];
else if (Constants.PLATFORM=="Canada-il")
contentjson=[...CanadaILJSON];
else if (Constants.PLATFORM=="Bahrain-il")
contentjson=[...BahrainILJSON];
else if (Constants.PLATFORM=="Bahrain")
contentjson=[...BahrainJSON];
else if (Constants.PLATFORM=="Newzealand-il")
contentjson=[...NewzealandILJSON];
else if (Constants.PLATFORM=="Newzealand")
contentjson=[...NewzealandJSON];
else if (Constants.PLATFORM=="Cambodia-il")
contentjson=[...CambodiaILJSON];
else if (Constants.PLATFORM=="Cambodia")
contentjson=[...CambodiaJSON];

function Footer(props) {


  useEffect(() => {
    /* global google */
     setwhatsapplink("https://api.whatsapp.com/send?phone=19173100929&text=Hello, I%20need%20help%20for%20eVisa%20to%20"+Constants.PLATFORM+"%20please")
    //console.log("DUDU");
  }, []);
  const mainPage = props.mainPage;
  const { i18n, t } = useTranslation();
  const handleClose = () => {
    setOpenTerms(false);
    setOpenPrivacy(false);
  //  navigate("/");

  };
  const [openTerms, setOpenTerms] = React.useState(false);
  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  const [whatsapplink, setwhatsapplink] = React.useState(null);
  const serviceTypeInfo = useSelector((state) => state.flightSearch.serviceTypeInfo);
  const priceInfo = useSelector((state) => state.flightSearch.priceInfo);
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  function htmlDecode(html) {
      return html.replace(/&([a-z]+);/ig, (match, entity) => {
        const entities = { amp: '&', apos: '\'', gt: '>', lt: '<', nbsp: '\xa0', quot: '"' };
        entity = entity.toLowerCase();
        if (entities.hasOwnProperty(entity)) {
          return entities[entity];
        }
        return match;
      });
    }
    const scriptCode = `
               <div style="min-height: 100px;" class="shopperapproved_widget sa_rotate sa_count1 sa_horizontal sa_bgWhite sa_colorBlack sa_borderGray sa_rounded sa_jMy sa_fixed sa_showlinks sa_large sa_showdate "></div><script type="text/javascript">var sa_interval = 5000;function saLoadScript(src) { var js = window.document.createElement('script'); js.src = src; js.type = 'text/javascript'; document.getElementsByTagName("head")[0].appendChild(js); } if (typeof(shopper_first) == 'undefined') saLoadScript('https://www.shopperapproved.com/widgets/testimonial/3.0/39217.js'); shopper_first = true; </script><div style="text-align:right;"><a aria-label="kosherwhere.com certificate URL" title="Reviews" class="sa_footer" href="https://www.shopperapproved.com/reviews/kosherwhere.com" target="_blank" rel="nofollow"><img class="sa_widget_footer" style="border: 0;" alt="kosherwhere.com widget logo" src=https://www.shopperapproved.com/widgets/widgetfooter-darklogo.png></a></div>

    `

  return (


    <Box sx={{ mb:4, color:"#fffff!important", border: '1px sold grey' }}>

    <Grid container direction="row" spacing={2}>
    <Grid  xs={12} sm={12} md={12} sx={{my:3}}>
      { false && (<Grid container direction="column">
          <Grid item xs={12}  md={12}>
           <section id="testimonial">
            <h2><img src="https://www.shopperapproved.com/award/images/33452-med.png" style={{border:0}} alt="Shopper Award" oncontextmenu="var d = new Date(); alert('Copying Prohibited by Law - This image and all included logos are copyrighted by shopperapproved \251 '+d.getFullYear()+'.'); return false;" />

            <br/>
            Testimonial </h2>





           </section>
           </Grid>
       </Grid>)}







      </Grid>
    </Grid>



      <br/>
  <Divider/>
    <Grid container direction="row" spacing={2}>
      <Grid  xs={12} sm={6} md={3} sx={{my:5}}>
        <Grid container direction="column">
                <Grid item xs={12} md={12} sx={{my:3}}>
                <img
                width="60px"
                src={`https://${Globals.SERVER_URL}/images/rating1.png`}
                alt={`Over 1M Happy Customers`}
                loading="lazy"
                />
              </Grid>


              <Grid item xs={12}  md={12}>
                <Typography
                  variant="subitle1"
                  component="a"
                  sx={{
                    mr: 2,
                    fontWeight: 300,
                    color: 'black',
                    textDecoration: 'none',
                  }}
                >

                {t('millions_customers')}
                </Typography>
             </Grid>
         </Grid>


      </Grid>

    <Grid  xs={12} sm={6} md={3} sx={{my:5}}>
        <Grid container direction="column">
                <Grid item xs={12} md={12} sx={{my:3}}>
                <img
                width="60px"
                src={`https://${Globals.SERVER_URL}/images/experience1.png`}
                alt={`Over 10 years of experience`}
                loading="lazy"
                />
              </Grid>


              <Grid item xs={12}  md={12}>
                <Typography
                  variant="subitle1"
                  component="a"
                  sx={{
                    mr: 2,
                    fontWeight: 300,
                    color: 'black',
                    textDecoration: 'none',
                  }}
                >

                  {t('experience')}
                </Typography>
             </Grid>
         </Grid>


      </Grid>
      <Grid  xs={12} sm={6} md={3} sx={{my:5}}>
        <Grid container direction="column">
                <Grid item xs={12} md={12} sx={{my:3}}>
                <img
                width="60px"
                src={`https://${Globals.SERVER_URL}/images/stopwatch.png`}
                alt={`99% On Time Delivery`}
                loading="lazy"
                />
              </Grid>


              <Grid item xs={12}  md={12}>
                <Typography
                  variant="subtitle1"
                  component="a"
                  sx={{
                    mr: 2,
                    fontWeight: 300,
                    color: 'black',
                    textDecoration: 'none',
                  }}
                >

                  {t('delivery')}
                </Typography>
             </Grid>
         </Grid>


      </Grid>
        <Grid  xs={12} sm={6} md={3} sx={{my:5}}>
        <Grid container direction="column">
              <Grid item xs={12} md={12} sx={{my:3}}>
                <img
                width="60px"
                src={`https://${Globals.SERVER_URL}/images/customer-review1.png`}
                alt={`Top Rated`}
                loading="lazy"
                />
              </Grid>


              <Grid item xs={12}  md={12}>
                <Typography
                  variant="subtitle1"
                  component="a"
                  sx={{
                    mr: 2,
                    fontWeight: 300,
                    color: 'black',
                    textDecoration: 'none',
                  }}
                >

                {t('top_rated')}
                </Typography>
             </Grid>
         </Grid>


      </Grid>

  <Grid container direction="column">
    </Grid>

    </Grid>



    <Grid  xs={12} sm={12} md={12} sx={{my:1}}>
      { Constants.LANG=="en" && (<Grid container direction="column">
              <Grid item xs={12} md={12} sx={{my:3}}>
              <img
              width="100px"
              src={`https://${Globals.SERVER_URL}/images/ahmad.jpeg`}
              alt={`We Support Multiple Languages`}
              loading="lazy"
              />
            </Grid>


          <Grid item xs={12}  md={12}>
              <Typography
                variant="subitle1"
                component="a"
                sx={{
                  mr: 2,
                  fontWeight: 300,
                  color: 'black',
                  textDecoration: 'none',
                }}
              >

              {`We Support Multiple Languages`}
              <br/>
              {'Nous soutenons plusieurs langues'}
              <br/>
              {`Wir unterstützen mehrere Sprachen`}
              <br/>
                 {`हम कई भाषाएँ समर्थन करते हैं `}
                   <br/>
                   {`نحن ندعم لغات متعددة `}

              </Typography>
           </Grid>
       </Grid>)}


    </Grid>
    {/*<Grid container  direction="row" spacing={2}>
    <Grid item xs={12} sm={6} md={3} >
        <Card   >
        <CardMedia
           component="img"
           image="https://${Globals.SERVER_URL}/images/customer-review1.png"
           alt="Over 1M"
         />
          <CardContent>
            <Typography
              variant="subtitle1"
              sx={{

                fontWeight: 700,
                color: 'black',
                textDecoration: 'none',
              }}
            >

            {`Over 1M Happy Customers `}
            </Typography>
          </CardContent>

        </Card>

    </Grid>
      <Grid item xs={12}  sm={6} md={3}  >
        <Card >
          <CardMedia
             component="img"

             image="https://${Globals.SERVER_URL}/images/experience1.png"
             alt="Experience"
           />
          <CardContent>

            <Typography
              variant="subtitle1"
              component="a"
              sx={{
                mr: 2,

                fontWeight: 700,

                color: 'black',
                textDecoration: 'none',
              }}
            >

          {`Over 10 years of experience `}
            </Typography>
          </CardContent>

        </Card>
      </Grid>

      <Grid item xs={12}  sm={6} md={3}  >
        <Card >
          <CardMedia
             component="img"
              width="100"
             image="https://${Globals.SERVER_URL}/images/rating1.png"
             alt="Top Rated"
           />
          <CardContent>

            <Typography
              variant="subtitle1"
              component="a"
              sx={{
                mr: 2,

                fontWeight: 700,

                color: 'black',
                textDecoration: 'none',
              }}
            >

          {`Top Rated `}
            </Typography>
          </CardContent>

        </Card>
      </Grid>

      <Grid item xs={12} sm={6} md={3}  >
        <Card >
          <CardMedia
             component="img"
              width="100"
             image="https://${Globals.SERVER_URL}/images/stopwatch.png"
             alt="99% On Time Delivery"
           />
          <CardContent>

            <Typography
              variant="subtitle1"
              component="a"
              sx={{
                mr: 2,

                fontWeight: 700,

                color: 'black',
                textDecoration: 'none',
              }}
            >

          {`99% On Time Delivery `}
            </Typography>
          </CardContent>

        </Card>
      </Grid>



    </Grid>*/}

    <Divider />
    {/*<Grid container direction="column"  sx={{my:7}}>

      <Grid item xs={4}  >
      <SupportAgentTwoToneIcon fontSize="large"/>
      </Grid>
        <Grid item xs={4} sx={{my:1,mx:4}} >
          <CheckCircleTwoToneIcon fontSize="medium"/>
          <Typography
            variant="subtitle1"

            component="a"

            sx={{
              mr: 2,

              fontWeight: 50,

              color: 'black',
              textDecoration: 'none',
            }}
          >
          &nbsp;
          {`24 to 72 working hours delivery time guarantee. `}
          </Typography>
          <b id="Quick_Facts"/>
      </Grid>
        <Grid item xs={4} sx={{my:1,mx:4}} >
          <CheckCircleTwoToneIcon fontSize="medium"/>
          <Typography
            variant="subtitle1"

            component="a"

            sx={{
              mr: 2,


              fontWeight: 50,

              color: 'black',
              textDecoration: 'none',
            }}
          >
          &nbsp;
          {`We are available 24x7 on Chat / WhatsApp / Phone / Email to assist you.`}
          </Typography>
      </Grid>
        <Grid item xs={4} sx={{my:1,mx:4}} >
          <CheckCircleTwoToneIcon fontSize="medium"/>
          <Typography
            variant="subtitle1"

            component="a"

            sx={{
              mr: 2,


              fontWeight: 50,

              color: 'black',
              textDecoration: 'none',
            }}
          >
          &nbsp;
          {`Prompt notifications on every stage via on Email and WhatsApp.`}
          </Typography>
      </Grid>
      <Grid item xs={4} sx={{my:1,mx:4}} >
          <CheckCircleTwoToneIcon fontSize="medium"/>
          <Typography
            variant="subtitle1"

            component="a"

            sx={{
              mr: 2,


              fontWeight: 50,

              color: 'black',
              textDecoration: 'none',
            }}
          >
          &nbsp;
          {`We will also be your sponsor. So no additional sponsor is required.`}
          </Typography>
      </Grid>
      </Grid>*/}
        <Divider />



        <Grid container direction="column"  >

          <Box sx={{my:3,backgroundColor:"#F6932111"}}>
          <Grid item xs={12}  >
          <img
          width="200px"
          src={!mainPage && Constants.PLATFORM=="Dubai"? `https://${Globals.SERVER_URL}/images/Dubai.jpeg`:`https://${Globals.SERVER_URL}/images/airport.jpg`}
          alt={`Arriving to Destination`}
          loading="lazy"
          />
          </Grid>
            <Grid item xs={12}   >
          <Typography
            variant="h5"
            component="a"
            sx={{
              mr: 2,
              fontWeight: 700,
              color: 'black',
              textDecoration: 'none',
            }}
          >
          <b id="Things_to_be_Done"/>
          {Constants.LANG=="he" && <b id="דברים_לעשות"/>}
          {!mainPage? (t('tips_to_stay_in') +  (Constants.LANG=="en"? Constants.PLATFORM:Constants.TITLE) ) :t('things_to_be_done_once')}
          </Typography>
        </Grid>
        <Divider />
          <Grid item xs={12} sx={{my:1,mx:4}}  >
            <Typography
              variant="subtitle1"
              component="a"
              sx={{
                mr: 2,
                fontWeight: 350,
                color: 'black',
                textDecoration: 'none',
              }}
            >
            &nbsp;
          <p>  <CheckCircleTwoToneIcon fontSize="medium"/> {t('mind_customs')} </p>
            <p>  <CheckCircleTwoToneIcon fontSize="medium"/>    {t('keep_passport_1')}  {Constants.LANG=="en"? Constants.PLATFORM:Constants.TITLE}  {t('keep_passport_2')}</p>
              <p>  <CheckCircleTwoToneIcon fontSize="medium"/>   {t('know_emergency')}</p>
                <p>  <CheckCircleTwoToneIcon fontSize="medium"/> {t('adhere_rules_1')} {Constants.LANG=="en"? Constants.PLATFORM:Constants.TITLE}  {t('adhere_rules_2')}</p>
            </Typography>
        </Grid>

        </Box>
    <Divider/>

    { !mainPage && <Grid container direction="column"  >
            <b id="Travel_Guide"/>
            <b id="מדריך_למטייל"/>
          <Grid item xs={4} sx={{my:4}} >
                <Typography
                  variant="h5"
                  component="a"
                  sx={{
                    mr: 2,
                    fontWeight: 700,
                    color: 'black',
                    textDecoration: 'none',
                  }}
                >
                &nbsp;
                {contentjson[0].title}
                </Typography>
          </Grid>
      <Divider />
          <Grid item xs={4} sx={{my:1,mx:4}} >
            <Typography
              variant="subtitle1"
              sx={{
                mr: 2,
                fontWeight: 350,
                color: 'black',
                textDecoration: 'none',
              }}
            >

            &nbsp;
            <p>{contentjson[0].text1}</p>
            <p>{contentjson[0].text2}</p>
            <p>{contentjson[0].text3}</p>
            </Typography>
        </Grid>
    </Grid>}
    <Divider/>

    {!mainPage && <Grid container direction="column"  >

      <Grid item xs={4} sx={{mx:4,my:4}} >
      <Typography
        variant="h5"
        sx={{
          mr: 2,
          fontWeight: 700,
          color: 'black',
          textDecoration: 'none',
        }}
      >
      &nbsp;
      {contentjson[1].title}
      </Typography>
      </Grid>
      <Divider />
          <Grid item xs={4} sx={{my:1,mx:4}} >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 350,

                color: 'black',
                textDecoration: 'none',
              }}
            >
            &nbsp;
            <p>{contentjson[1].text1}</p>
            <p>{contentjson[1].text2}</p>
            <p>{contentjson[1].text3}</p>
            </Typography>
        </Grid>
    </Grid>}


    {!mainPage && <Grid container direction="column"  >
      <Grid item xs={4} sx={{my:3}} >
      <img
      width="300px"
      src={`https://${Globals.SERVER_URL}/images/` + Constants.PLATFORM+"/"+Constants.PLATFORM + `.jpg`}
      alt={`Image`}
      loading="lazy"
      />
      </Grid>
      <Divider/>
      <b id="Facts_and_Figures"/>
        <b id="עובדות_ומספרים"/>
      <Grid item xs={4} sx={{mx:4,my:4}} >
      <Typography
        variant="h5"
        sx={{
          mr: 2,
          fontWeight: 700,

          color: 'black',
          textDecoration: 'none',
        }}
      >
      &nbsp;
      {contentjson[2].title}
      </Typography>
      </Grid>
      <Divider />
      <Grid item xs={4} sx={{my:1,mx:4,backgroundColor:"#F6932111"}} >

        <Typography
          variant="subtitle1"
          component="a"
          sx={{
            mr: 2,
            fontWeight: 350,
            color: 'black',
            textDecoration: 'none',
          }}
        >
        &nbsp;
        <p>{contentjson[2].text1}</p>
        <p>{contentjson[2].text2}</p>
        <p>{contentjson[2].text3}</p>
        <p>{contentjson[2].text4}</p>
        </Typography>
    </Grid>
    </Grid>}
        <Divider/>



      <b id="Contact_us"/>
        <b id="צור_קשר"/>
      <Grid item xs={12} sx={{my:4,mx:4}}>
        <Typography
          variant="h4"
           alignItems="center"
          sx={{
            mr: 2,
            float:"center",
            fontWeight: 500,
            color: 'orange',
            textDecoration: 'none',
          }}
        >
        &nbsp;
          {t('ways_to_connect_with_us')}
        </Typography>

    </Grid>


    <Grid container  >
        {/*<Grid item xs={12}  md={4}  sx={{mb:2}}>
              <Grid item xs={12}>
                <WhatsAppIcon fontSize="large"/>
                <Typography
                  variant="h5"

                  href="https://api.whatsapp.com/send?phone=19173100929&text=Hi,%20i%20just%20visited%20your%20website%20and%20i%20have%20some%20queries%20related%20to%20it."
                  sx={{
                    mr: 2,
                    fontWeight: 500,
                    color: 'black',
                    textDecoration: 'none',
                  }}
                >
                &nbsp;
                {`Whatsapp`}
                </Typography>
              </Grid>
              <Grid item xs={12}  >
                <Typography
                  variant="subtitle1"
                //  noWrap
                  component="a"
                  href="https://api.whatsapp.com/send?phone=19173100929&text=Hi,%20i%20just%20visited%20your%20website%20and%20i%20have%20some%20queries%20related%20to%20it."
                  sx={{
                    mr: 2,
                    fontWeight: 500,
                    color: 'black',
                    textDecoration: 'none',
                  }}
                >
                &nbsp;
                {`+1-917-688-4284`}
                </Typography>
              </Grid>

          </Grid>*/}
        {/*  <Grid item xs={12}  md={4}  sx={{mb:2}}>
                <Grid item xs={12}>
                  <WhatsAppIcon onClick={(event, newValue) => {
                      window.open("https://api.whatsapp.com/send?phone=19173100929", "_blank");
                    }} fontSize="large"/>
                  <Typography
                    variant="h5"

                    onClick={(event, newValue) => {
                        window.open("https://api.whatsapp.com/send?phone=19173100929", "_blank");
                    }}
                    href={whatsapplink}
                    sx={{
                      mr: 2,
                      fontWeight: 500,
                      color: 'black',
                      textDecoration: 'none',
                    }}
                  >
                  &nbsp;
                  {`Whatsapp`}
                  </Typography>
                </Grid>
                <Grid item xs={12} >
                  <Typography
                    variant="subtitle1"
                    dir={Constants.LANG=="he"? "ltr":"ltr"}
                    component="a"
                    href={whatsapplink}
                    sx={{
                      mr: 2,
                      fontWeight: 500,
                      color: 'black',
                      textDecoration: 'none',
                    }}
                  >
                  &nbsp;
                  {'+1 (917) 310-0929'}
                  </Typography>
                </Grid>
            </Grid>*/}
          <Grid item xs={12}  md={6}  sx={{mb:4}} >

                <Grid item xs={12}>
                  <ChatIcon    onClick={(event, newValue) => {
                      void(Tawk_API.toggle());
                    }}  fontSize="large"/>
                  <Typography
                    variant="h5"


                  onClick={(event, newValue) => {
                    void(Tawk_API.toggle());
                  }}
                    sx={{
                      mr: 2,
                      fontWeight: 500,
                      color: 'black',
                      textDecoration: 'none',
                    }}
                  >
                  &nbsp;
                  {t('online_chat_assist')}
                  </Typography>
                </Grid>
                <Grid item xs={12} >
                  <Typography
                    variant="subtitle1"

                    component="a"
                    href="javascript:void(Tawk_API.toggle())"
                    sx={{
                      mr: 2,
                      fontWeight: 500,
                      color: 'black',
                      textDecoration: 'none',
                    }}
                  >
                  &nbsp;
                  {t('our_team')}
                  </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}  sx={{mb:2}} >

                  <Grid item xs={12} >
                    <AlternateEmailIcon     onClick={(event, newValue) => {
                            window.open(`mailto:info@${Globals.SERVER_URL}?subject=Need%20Help`, "_blank");
                        }} fontSize="large"/>
                    <Typography
                      variant="h5"
                      component="a"
                      onClick={(event, newValue) => {
                             window.open(`mailto:info@${Globals.SERVER_URL}?subject=Need%20Help`, "_blank");
                         }}
                      sx={{
                        mr: 2,
                        fontWeight: 500,
                        color: 'black',
                        textDecoration: 'none',
                      }}
                    >
                    &nbsp;
                    {t('email')}
                    <br/>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}  >
                    <Typography
                      variant="subtitle1"
                      onClick={(event, newValue) => {
                             window.open(`mailto:info@${Globals.SERVER_URL}?subject=Need%20Help`, "_blank");
                         }}
                      sx={{
                        mr: 2,
                        fontWeight: 500,
                        color: 'black',
                        textDecoration: 'none',
                      }}
                    >
                    &nbsp;
                    {/*<p>{`KOSHERWHERE LLC`}</p>*/}
                    {`info@${Globals.SERVER_URL}`}


                    </Typography>
                  </Grid>

              </Grid>

            <Grid item xs={12} md={12}  sx={{mb:2}} >

                  <Grid item xs={12} >
                    <BusinessIcon fontSize="large"/>
                    <Typography
                      variant="h5"
                      component="a"
                      sx={{
                        mr: 2,
                        fontWeight: 500,
                        color: 'black',
                        textDecoration: 'none',
                      }}
                    >
                    &nbsp;
                    {t('address')}
                    <br/>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}  >
                    <Typography
                      variant="subtitle1"

                      sx={{
                        mr: 2,

                        fontWeight: 500,

                        color: 'black',
                        textDecoration: 'none',
                      }}
                    >
                    &nbsp;
                    {/*<p>{`KOSHERWHERE LLC`}</p>*/}
                    {t('street')}<br/>
                    {t('city_zip')}<br/>
                    {t('usa')}<br/>
                    </Typography>
                  </Grid>

              </Grid>

    </Grid>

      <Divider/>
        <Grid container  sx={{my:4}}  >

          <Grid item xs={4}   >
          <img
          width="100px"
          src={`https://${Globals.SERVER_URL}/images/secure-transaction.png`}
          alt={`Arriving to Destination`}
          loading="lazy"
          />
          </Grid>
          <Grid item xs={4} md={4} >
          <Typography
            variant="h1"

            sx={{
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'black',
              textDecoration: 'none',
            }}
          >
          {<Box component="span" >

            {Globals.SERVER=="GOVIS.AI" && <img
            width="100px"

            align="center"

            //display="inline-block"
            src={`https://${Globals.SERVER_URL}/images/GOVIS.png`}
            alt={`Govis.ai`}
          loading="lazy"
            />}

            <br/>

            </Box>}


        {!mainPage && !Constants.OVERRIDE_LOGO && <span className={Constants.FLAG_CODE}></span>}
        {!mainPage && Constants.OVERRIDE_LOGO &&  <img
          width="80px"

          align="center"

          //display="inline-block"
          src={`https://${Globals.SERVER_URL}/${Constants.PLATFORM.toLowerCase()}/logo.jpg`}
          alt={`Govis.ai`}
        loading="lazy"
          />}


          </Typography>


          </Grid>
          <Grid item xs={4}   >
          <img
          width="100px"
          src={`https://${Globals.SERVER_URL}/images/secure-payment.png`}
          alt={`Secure Payment`}
          loading="lazy"
          />
          </Grid>

        </Grid>
        <Divider/>
        <Grid item xs={4} md={4} sx={{my:4}}>
        <Typography
          variant="h2"
          sx={{

            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'black',
            textDecoration: 'none',
          }}
        >
      {t('about_us')}


        </Typography>


        </Grid>

        <Grid item xs={12} md={4} sx={{my:4,mx:4}}>
        <Typography
          variant="caption"


          sx={{


            fontWeight: 300,
            letterSpacing: '.1rem',
            color: 'black',
            textDecoration: 'none',
          }}
        >
        {t('about_us_1')} {Globals.SERVER}  {t('about_us_2')}
      <br/>
      {t('about_us_3')}

        </Typography>



        </Grid>
          <Grid container direction="row"  >
        {    false  && (
  <Grid item xs={12} sm={6} md={6}  >
  <Typography
    variant="caption"
    sx={{
      fontWeight: 600,
      color: 'black',
      textDecoration: 'none',
    }}
  >
  &nbsp;

  <img
  width="180px"

  center

  display="inline"
  src={`https://${Globals.SERVER_URL}/images/AI.png`}
  //alt={`Providing Official eVisa`}
  loading="lazy"
  />    <br/>{t('effortless_ai_experience')}

  </Typography>
  <br/>

  </Grid>)}


      {    true  && (
  <Grid item xs={12} sm={12} md={12}  >
  <Typography
    variant="caption"
    sx={{
      fontWeight: 600,
      color: 'black',
      textDecoration: 'none',
    }}
  >
  &nbsp;

  <img
  width="250px"

  center

  display="inline"
  src={`https://${Globals.SERVER_URL}/images/AI-1.jpg`}
  //alt={`Providing Official eVisa`}
  loading="lazy"
  />    <br/>{t('real_time_process')}

  </Typography>
  <br/>

  </Grid>)}

  </Grid>
  <br/>
        <Divider/>


        <Grid item xs={12} md={4} sx={{my:4,mx:4}}>
        <Typography
          variant="subtitle2"
        //  noWrap
          component="a"

          sx={{
          //  mr: 2,

            //display: { xs: 'flex', md: 'none' },
            //flexGrow: 1,

            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'black',
            textDecoration: 'none',
          }}
        >
        {t('payment_methods')}


        </Typography>



        </Grid>



        <Box  display="flex"  justifyContent="center" alignItems="center" justify="center">
        <Grid   item xs={12} alignItems="center"  justify="center">

        <img
        width="300px"
        style={{backgroundColor:`black`}}
        src={`https://${Globals.SERVER_URL}/images/payment.png`}
        alt={`Paymnet Methods`}
      loading="lazy"
        />
        <br/>
        <img
        width="150px"
        center
        src={`https://${Globals.SERVER_URL}/images/PayPal.png`}
        alt={`Paymnet Methods`}
      loading="lazy"
        />
        </Grid>
        </Box>
        <br/>
        <Divider/>
        <Grid item xs={12} md={4} sx={{my:4,mx:4,backgroundColor:"white"}}>
        <Typography
          variant="caption"
        //  noWrap
          component="a"

          sx={{
          //  mr: 2,

            //display: { xs: 'flex', md: 'none' },
            //flexGrow: 1,

            fontWeight: 450,
            letterSpacing: '.1rem',
            color: 'black',
            textDecoration: 'none',
          }}
        >
      {t('all_rights_reserved')} {currentYear}{` © www.${Globals.SERVER_URL} `}
      <br/>
      {t('head_office')}



        </Typography>

        <Typography
          variant="caption"


          sx={{

            fontSize:10,
            fontWeight: 350,
            letterSpacing: '.1rem',
            color: 'black',
            textDecoration: 'none',
          }}
        >
        <br/>
      {!mainPage &&t('disclaimer').replaceAll("{GOV}",Constants.TITLE)}
       {mainPage && t('disclaimer_main')}



        </Typography>


        <Typography
          variant="caption"


          sx={{

            fontSize:10,
            fontWeight: 350,
            letterSpacing: '.1rem',
            color: 'black',
            textDecoration: 'none',
          }}
        >
        <br/>
      {Constants.PLATFORM=='Canada'?  t('disclaimer_add_canada').replaceAll("{GOV}",Constants.TITLE):""}
      {Constants.PLATFORM=='Us'? t('disclaimer_add_us').replaceAll("{GOV}",Constants.TITLE):""}



        </Typography>
        <div>
         { priceInfo.result && serviceTypeInfo.result && (<>
        <br/>
        <Typography
          variant="subtitle"
          onClick={(event: React.ChangeEvent<HTMLInputElement>) => {

               setOpenTerms(true)


          }}
          sx={{

            fontWeight: 500,
            letterSpacing: '.1rem',
            color: 'black',
            textDecoration: 'none',
          }}
        >
        <br/>
      {t('Terms of Use')} {' | '}


        </Typography>
          </>)}
        <Typography
          variant="subtitle"
          onClick={(event: React.ChangeEvent<HTMLInputElement>) => {

               setOpenPrivacy(true)


          }}
          sx={{

            fontWeight: 500,
            letterSpacing: '.1rem',
            color: 'black',
            textDecoration: 'none',
          }}
        >


      {t('Privacy Policy')}
      </Typography>
      </div>
      { priceInfo.result && serviceTypeInfo.result && (<Dialog
      //   fullScreen={fullScreen}
         open={openTerms}
         onClose={handleClose}
         aria-labelledby="responsive-dialog-title"
       >
         <DialogTitle id="responsive-dialog-title">
           {"Terms of Use"}
         </DialogTitle>
         <DialogContent>
           <DialogContentText>

                              <TermsAndConditions/>
           </DialogContentText>
         </DialogContent>
         <DialogActions>
           {/*<Button autoFocus onClick={handleClose}>
             Disagree
           </Button>*/}
           <Button onClick={handleClose} autoFocus>
             AGREE
           </Button>
         </DialogActions>
       </Dialog>)}

       {  (<Dialog
       //   fullScreen={fullScreen}
          open={openPrivacy}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Privay Policy"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>

                              <Privacy/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/*<Button autoFocus onClick={handleClose}>
              Disagree
            </Button>*/}
            <Button onClick={handleClose} autoFocus>
              AGREE
            </Button>
          </DialogActions>
        </Dialog>)}




        </Grid>


      </Grid>

    </Box>
  );
}



export default Footer;
